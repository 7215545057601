import React, { useState, useEffect } from "react";
import { FaRedo } from "react-icons/fa";
import { toast } from "react-hot-toast";
import "./RotatingTextBoxMinno.css";

function RotatingTextBoxMinno({ actions }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    setShowTable(false);
    setTableData(null);
    setKey((prevKey) => prevKey + 1);
  }, [currentIndex]);

  const handleSubmit = () => {
    const currentAction = actions[currentIndex];
    currentAction.action();

    if (currentAction.table) {
      setShowTable(true);
      setTableData(currentAction.table);
      setKey((prevKey) => prevKey + 1);
    } else if (currentAction.toast) {
      toast.success(currentAction.toast, {
        duration: 3000,
        position: "top-center",
        style: {
          backgroundColor: "#000000",
          color: "#7FF257",
          border: "1px solid #3C3C3C",
        },
        iconTheme: {
          primary: "#7FF257",
          secondary: "#0C1B09",
        },
      });
    }
  };

  const handleRefresh = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % actions.length);
  };

  return (
    <div className="Minno-rotating-text-box">
      <p className="rotating-text">{actions[currentIndex].text}</p>
      <div className="button-container">
        <button
          onClick={handleRefresh}
          className="refresh-btn"
          aria-label="Next Tip"
        >
          <FaRedo className="restart-icon" />
        </button>
        <button onClick={handleSubmit} className="submit-btn">
          Do it
        </button>
      </div>
      {showTable && tableData && (
        <div key={key} className="table-container fade-in">
          <table>
            <thead>
              <tr>
                {tableData.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RotatingTextBoxMinno;
