import React, { useState } from "react";
import placeholderImage from "../pages/Blog/images/placeholder.png";

const ImageWithFallback = ({ src, alt, width, height, ...props }) => {
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return (
    <img
      src={error ? placeholderImage : src}
      alt={alt}
      width={width}
      height={height}
      onError={handleError}
      {...props}
    />
  );
};

export default ImageWithFallback;
