import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ChartComponent = () => {
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [65, 59, 80, 81, 56, 55, 40],
        borderColor: '#7FF257',
        backgroundColor: 'rgba(127, 242, 87, .2)',
        borderWidth: 2, // Set the border width
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows the chart to ignore the default aspect ratio
    height: 300, // Set the height of the chart (in pixels)
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 14, // Set the font size for the legend labels
          },
          color: '#ffffff', // Set the color for the legend labels
        },
      },
      title: {
        display: false,
        text: 'Sample Chart',
        font: {
          size: 16, // Set the font size for the title
        },
        color: '#ffffff', // Set the color for the title
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff', // Set the color for the x-axis labels
          font: {
            size: 12, // Set the font size for the x-axis labels
          },
        },
        grid: {
          color: '#264622', // Set the color for the x-axis grid lines
        },
      },
      y: {
        ticks: {
          color: '#ffffff', // Set the color for the y-axis labels
          font: {
            size: 12, // Set the font size for the y-axis labels
          },
        },
        grid: {
          color: '#264622', // Set the color for the y-axis grid lines
        },
      },
    },
  };

  return <div style={{ height: '300px' }}><Line data={data} options={options} /></div>;
};

export default ChartComponent;