import "./Minno.css";

import React, { useState, useCallback, useRef, useEffect } from "react";
import Confetti from "react-confetti";
import { Toaster, toast } from "react-hot-toast";
import RotatingTextBoxMinno from "../../components/RotatingTextBoxMinno";
import leapyearLogo from "../../images/leapyear.png";
import pioneerLogo from "../../images/pioneer.png";
import devIcon from "./images/dev.png";
import locationIcon from "./images/location.png";
import chartIcon from "./images/chart.png";
import helpIcon from "./images/help.png";
import fastIcon from "./images/bolt.png";
import gearIcon from "./images/gear.png";
import bulbIcon from "./images/bulb.png";
import mobileIcon from "./images/mobile.png";
import saveIcon from "./images/save.png";
import heartIcon from "./images/heart.png";
import { FaLink, FaApple } from "react-icons/fa";
import minnoLogo from "./images/minno.png";
import { Link } from "react-router-dom";

function Minno() {
  const [email, setEmail] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);

  useEffect(() => {
    document.body.className = "minno-body";
    return () => {
      document.body.className = "";
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show confetti and notification immediately
    setShowConfetti(true);
    toast.success("Signup successful!", {
      position: "top-center",
      style: {
        backgroundColor: "#000000",
        color: "#7FF257",
        border: "1px solid #3C3C3C",
      },
      iconTheme: {
        primary: "#7FF257",
        secondary: "#000000",
      },
    });

    // Send the data to Google Sheets in the background
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbx4u2_GxoTyl7gZQj5eRLk1uwH6TaHh1MkqY5PmDdqHMejEiC4aADvkWTGnuEWtiUB3pA/exec",
        {
          method: "POST",
          body: new URLSearchParams({ email }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error("There was an error submitting the form.", {
        style: {
          backgroundColor: "#000000",
          color: "#FF6C3D",
          border: "1px solid #3C3C3C",
        },
        iconTheme: {
          primary: "#FF6C3D",
          secondary: "#000000",
        },
      });
    }

    // Reset the form
    setEmail("");

    // Stop confetti after a delay
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000); // 3 seconds
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        toast.success("Link copied to clipboard!", {
          style: {
            backgroundColor: "#000000",
            color: "#7FF257",
            border: "1px solid #3C3C3C",
          },
          iconTheme: {
            primary: "#7FF257",
            secondary: "#000000",
          },
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.error("Failed to copy link", {
          style: {
            backgroundColor: "#000000",
            color: "#FF6C3D",
            border: "1px solid #3C3C3C",
          },
          iconTheme: {
            primary: "#FF6C3D",
            secondary: "#000000",
          },
        });
      });
  };

  const actions = [
    {
      text: "Show me Netflix and their competitors PE ratio",
      action: () => {},
      table: {
        headers: ["Company", "PE Ratio"],
        rows: [
          ["Netflix", "30.5"],
          ["Disney+", "25.2"],
          ["Amazon Prime", "35.8"],
          ["Hulu", "28.7"],
          ["Apple TV+", "32.1"],
          ["HBO Max", "27.9"],
          ["Paramount+", "22.3"],
          ["Peacock", "20.6"],
          ["YouTube TV", "33.4"],
          ["Sling TV", "18.9"],
        ],
      },
    },
    {
      text: "Create a list with AAPL and AMZN",
      action: () => {},
      toast: "Demo: List created!",
    },
    {
      text: "Compare APPL and MSFT performance",
      action: () => {},
      table: {
        headers: ["Metric", "Apple (AAPL)", "Microsoft (MSFT)"],
        rows: [
          ["Current Price", "$150.25", "$300.80"],
          ["52-Week High", "$182.94", "$349.67"],
          ["52-Week Low", "$124.17", "$213.43"],
          ["Market Cap", "$2.37T", "$2.24T"],
          ["P/E Ratio", "25.13", "32.45"],
          ["Dividend Yield", "0.62%", "0.87%"],
          ["YTD Performance", "+15.8%", "+22.3%"],
        ],
      },
    },
    {
      text: "How can I share this?",
      action: () => {
        navigator.clipboard
          .writeText(window.location.href)
          .then(() => {})
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      },
      toast: "Link copied to clipboard!",
    },
  ];

  const handleActionPerformed = useCallback(
    (index) => {
      const action = actions[index];
      setCurrentAction(action);
      action.action();
    },
    [actions],
  );

  const handleActionComplete = useCallback(() => {
    setCurrentAction(null);
  }, []);

  return (
    <div className="Minno minno-element">
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            style: {
              backgroundColor: "#000000",
              color: "#7FF257",
              border: "1px solid #3C3C3C",
            },
            iconTheme: {
              primary: "#7FF257",
              secondary: "#000000",
            },
          },
          error: {
            style: {
              background: "#000000",
              color: "#FF6C3D",
              border: "1px solid #3C3C3C",
            },
            iconTheme: {
              primary: "#FF6C3D",
              secondary: "#000000",
            },
          },
        }}
      />
      {showConfetti && <Confetti />}
      <header className="App-header minno-element">
        <div className="header-content minno-element">
          <div className="logo-header minno-element">
            <h3 id="natural-logo" className="minno-element">
              🌿 Natural
            </h3>
            <h3 id="vertical-line" className="minno-element">
              |
            </h3>
            <img
              src={minnoLogo}
              id="template-logo"
              className="minno-element"
              alt="Minno"
            />
          </div>
          <div className="nav-actions minno-element">
            <Link to="/blog" className="nav-link minno-element">
              Blog
            </Link>
            <button
              id="copy-link"
              className="icon-button minno-element"
              onClick={handleCopyLink}
            >
              <FaLink />
            </button>
            <div className="contact-link minno-element">
              <a href="mailto:founders@naturalui.com" className="minno-element">
                Contact us
              </a>
            </div>
          </div>
        </div>
        <div className="hero minno-element">
          <div className="text-content minno-element">
            <h1 id="header" className="minno-element">
              Complete any action in{" "}
              <span className="highlight minno-element">seconds</span>
            </h1>
            <p id="sub-header" className="minno-element">
              Control any website with natural language
            </p>
            <form onSubmit={handleSubmit} className="email-form minno-element">
              <div className="input-button-group minno-element">
                <input
                  id="email"
                  className="minno-element"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="input-button minno-element">
                  Join Waitlist
                </button>
              </div>
            </form>
          </div>
          <div className="image-container minno-element">
            <RotatingTextBoxMinno
              actions={actions}
              onActionPerformed={handleActionPerformed}
              currentAction={currentAction}
              onActionComplete={handleActionComplete}
            />
          </div>
        </div>
      </header>
      <main className="minno-element">
        <div className="download-section minno-element">
          <a
            href="https://apps.apple.com/us/app/minno-finance-stock-analysis/id1639001130"
            target="_blank"
            rel="noopener noreferrer"
            className="download-button minno-element"
          >
            <FaApple className="apple-icon" />
            Download Minno
          </a>
        </div>
        <div className="features-container minno-element">
          <div className="features minno-element">
            <div className="feature-item minno-element">
              <img
                src={locationIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                AI Control
              </h2>
              <p id="feature-text" className="minno-element">
                Let Natural complete manual financial analysis tasks for you.
              </p>
            </div>
            <div className="feature-item minno-element">
              <img
                src={fastIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                Fast multi-step actions
              </h2>
              <p id="feature-text" className="minno-element">
                Get from intent to task completion in seconds.
              </p>
            </div>
            <div className="feature-item minno-element">
              <img
                src={gearIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                Discover hidden features
              </h2>
              <p id="feature-text" className="minno-element">
                Don't waste time searching for finance tools- Natural finds them
                for you.
              </p>
            </div>
          </div>
          <div className="features minno-element">
            <div className="feature-item minno-element">
              <img
                src={mobileIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                Easy mobile navigation
              </h2>
              <p id="feature-text" className="minno-element">
                Don't worry about navigating Minno.
              </p>
            </div>
            <div className="feature-item minno-element">
              <img
                src={saveIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                Save important workflows
              </h2>
              <p id="feature-text" className="minno-element">
                Natural remembers your most common requests.
              </p>
            </div>
            <div className="feature-item minno-element">
              <img
                src={helpIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                Help docs are dead
              </h2>
              <p id="feature-text" className="minno-element">
                Never waste time searching for answers to finance questions.
              </p>
            </div>
          </div>
        </div>

        <div className="founders minno-element">
          <h2 id="logo-text" className="minno-element">
            Trusted by...
          </h2>
          <div className="logos minno-element">
            <img
              src={leapyearLogo}
              id="leapyear"
              className="minno-element"
              alt="Leapyear"
            />
            <img
              src={pioneerLogo}
              id="pioneer"
              className="minno-element"
              alt="YC Pioneer Program"
            />
          </div>
        </div>

        <div className="main-features minno-element">
          <h2 id="features-text" className="minno-element">
            Better UX,{" "}
            <span className="highlight minno-element">
              better financial analysis
            </span>
          </h2>

          <div className="features-section minno-element">
            <div className="features-2 minno-element">
              <div className="feature-item-2 minno-element">
                <img
                  src={bulbIcon}
                  id="icon"
                  className="minno-element"
                  alt="Feature Icon"
                />
                <h2 id="feature-heading" className="minno-element">
                  Product insights
                </h2>
                <p id="feature-text" className="minno-element">
                  View & analyze what users are asking for.
                </p>
              </div>
              <div className="feature-item-2 minno-element">
                <img
                  src={helpIcon}
                  id="icon"
                  className="minno-element"
                  alt="Feature Icon"
                />
                <h2 id="feature-heading" className="minno-element">
                  Fewer support tickets
                </h2>
                <p id="feature-text" className="minno-element">
                  Natural meets users where they are, without delay.
                </p>
              </div>
            </div>
            <div className="features-2 minno-element">
              <div className="feature-item-2 minno-element">
                <img
                  src={chartIcon}
                  id="icon"
                  className="minno-element"
                  alt="Feature Icon"
                />
                <h2 id="feature-heading" className="minno-element">
                  Higher customer satisfaction
                </h2>
                <p id="feature-text" className="minno-element">
                  Better ease of use and instant access to data.
                </p>
              </div>
              <div className="feature-item-2 minno-element">
                <img
                  src={heartIcon}
                  id="icon"
                  className="minno-element"
                  alt="Feature Icon"
                />
                <h2 id="feature-heading" className="minno-element">
                  Lower churn
                </h2>
                <p id="feature-text" className="minno-element">
                  An experience so good, your customers won't want to leave.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="dev-section-container minno-element">
          <h2 id="features-text" className="minno-element">
            Easy for developers
          </h2>
          <div className="dev-section minno-element">
            <div className="feature-item minno-element">
              <img
                src={devIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                Simple integration
              </h2>
              <p id="feature-text" className="minno-element">
                Add a code snippet & configure Natural from the web.
              </p>
            </div>
            <div className="feature-item minno-element">
              <img
                src={chartIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                Outperform web agents
              </h2>
              <p id="feature-text" className="minno-element">
                Natural integrates with your backend to call functions reliably.
              </p>
            </div>
            <div className="feature-item minno-element">
              <img
                src={devIcon}
                id="icon"
                className="minno-element"
                alt="Feature Icon"
              />
              <h2 id="feature-heading" className="minno-element">
                More maintainable than RPA
              </h2>
              <p id="feature-text" className="minno-element">
                Functions are automatically updated in your CI/CD pipeline.
              </p>
            </div>
          </div>
        </div>

        <div className="story minno-element">
          <h1 id="story-heading" className="minno-element">
            A smart{" "}
            <span className="highlight minno-element">
              financial task agent
            </span>
          </h1>
          <div className="story-content minno-element">
            <p id="story-text" className="minno-element">
              When your analysts are lost and frustrated, the last thing they
              want to do is to search for answers in disparate sources. Natural
              meets users where they are and instantly knows how to complete any
              task.
            </p>
            <ul className="minno-element">
              <li className="minno-element">
                <strong className="minno-element">AI Control:</strong> Users
                want to get financial analysis done, fast. Natural completes
                manual data collection tasks for them in seconds.
              </li>
              <li className="minno-element">
                <strong className="minno-element">Insights:</strong> When users
                struggle with your financial products' UI, it's important
                information for improvement. So we surface all of it to you,
                while solving user pain points immediately.
              </li>
              <li className="minno-element">
                <strong className="minno-element">Easy:</strong> Add a code
                snippet to your product (like Posthog) and configure Natural on
                the web.
              </li>
            </ul>
            <p id="story-text" className="minno-element">
              Our goal is to eliminate the need for help docs, knowledge bases,
              and endless chats with support. Join us in our mission to make
              financial analysis more accessible.
            </p>
            <h3 className="minno-element">Colin and Grayson</h3>
            <p className="minno-element">
              Founders of Natural (in collaboration with Minno)
            </p>
          </div>
        </div>
      </main>
      <footer className="minno-element">
        <div className="inner-footer minno-element">
          <div className="footer-logo minno-element">
            <h3 id="natural-logo" className="minno-element">
              🌿 Natural
            </h3>
            <div className="contact-link minno-element">
              <a href="mailto:founders@naturalui.com" className="minno-element">
                Contact us
              </a>
            </div>
            <div className="contact-link minno-element">
              <a
                href="https://twitter.com/joinnatural"
                className="contact-link minno-element"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </div>
            <div className="contact-link minno-element">
              <a
                href="https://www.linkedin.com/company/naturalui"
                className="contact-link minno-element"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="input-email-form-2 minno-element">
            <form
              onSubmit={handleSubmit}
              className="email-form-2 minno-element"
            >
              <div className="input-button-group minno-element">
                <input
                  id="email-2"
                  className="minno-element"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="input-button minno-element">
                  Join Waitlist
                </button>
              </div>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Minno;
