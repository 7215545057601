import placeholderImage from "./images/placeholder.png";
import testAuthImage from "./images/testauth.png";
import naturalImage from "./images/naturallogo.png";
import milesImage from "./images/miles.png";
const blogPosts = [
  {
    slug: "a-ux-problem",
    title: "A UX Problem: Why Software Still Fails to Be User-Friendly",
    author: "Miles Lang",
    authorImage: milesImage,
    date: "2024-09-11",
    content: "a-ux-problem.md",
    image: placeholderImage,
    highlighted: false,
    mainHighlight: false,
  },
  {
    slug: "introducing-natural",
    title: "Introducing Natural: AI-Powered Task Completion",
    author: "Team Natural",
    authorImage: naturalImage,
    date: "2024-09-08",
    content: "introducing-natural.md",
    image: placeholderImage,
    highlighted: true,
    mainHighlight: true,
  },
  {
    slug: "what-is-ux-and-why-is-it-important",
    title: "What Is UX and Why Is It Important",
    author: "Miles Lang",
    authorImage: milesImage,
    date: "2024-09-17",
    content: "What-Is-UX-and-Why-Is-It-Important.md",
    image: placeholderImage,
    highlighted: false,
    mainHighlight: false,
  },
  {
    slug: "how-to-integrate-ai-into-your-software-business-effectively",
    title: "How to Integrate AI into Your Software Business Effectively",
    author: "Miles Lang",
    authorImage: milesImage,
    date: "2024-09-19",
    content: "how-to-integrate-ai-into-your-software-business-effectively.md",
    image: placeholderImage,
    highlighted: false,
    mainHighlight: false,
  },
  // Add more blog posts here
];

export default blogPosts;
export { placeholderImage, testAuthImage };
