import React, { useState } from "react";
import { FaRedo } from "react-icons/fa";
import { toast } from "react-hot-toast";
import "./RotatingTextBox.css";

function RotatingTextBox({ actions }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSubmit = () => {
    const currentAction = actions[currentIndex];
    currentAction.action();
    toast.success(currentAction.toastMessage, {
      duration: 3000,
      position: "top-center",
      style: {
        backgroundColor: "#0C1B09",
        color: "#7FF257",
        border: "1px solid #3D493A",
      },
      iconTheme: {
        primary: "#7FF257",
        secondary: "#0C1B09",
      },
    });
  };

  const handleRefresh = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % actions.length);
  };

  return (
    <div className="Clippy-rotating-text-box">
      <p className="rotating-text">{actions[currentIndex].text}</p>
      <div className="button-container">
        <button
          onClick={handleRefresh}
          className="refresh-btn"
          aria-label="Next Tip"
        >
          <FaRedo className="restart-icon" />
        </button>
        <button onClick={handleSubmit} className="submit-btn">
          Do it
        </button>
      </div>
    </div>
  );
}

export default RotatingTextBox;
